.admin {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-size: 25px !important;
}

.company {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-size: 20px !important;
}
/* 
.modal {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-size: 20 !important;
} */
